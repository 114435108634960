/****** Background images *******/

.bg-quotation {
  background-image: url(../icons/quotes.svg);
  background-position: center center;
  @apply bg-no-repeat;
}

.bg-theme {
  background-image: url(../images/theme.webp);
}

.bg-booking {
  background-image: url(../images/home.jpg);
  background-size: cover;
  @apply bg-no-repeat;
}

@screen sm {
  .bg-booking {
    background-image: url(../images/home-headband.webp);
  }
}

@screen xl {
  .bg-booking {
    background-image: url(../images/home-headband@2x.webp);
  }
}

/****** Glide Reviews *******/

.glide-reviews .glide__arrows button {
  @apply bg-gold-300 border-0 rounded-none shadow-transparent;
}

.glide-reviews .glide__arrow--left {
  left: 0;
}

.glide-reviews .glide__arrow--right {
  right: 0;
}
